import { useTracker } from "@ctv/core";
import { TabsPropsSeo, TabsSeo } from "@ctv/momentum";
import { TrackingType } from "@ctv/shared/tracking/types";

type Props<T extends string> = TabsPropsSeo<T> & {
  track?: (featureName: T) => TrackingType;
};

export default function TrackingTabsSeo<T extends string>(props: Props<T>) {
  const { track, onPress, ...rest } = props;

  const doTracking = useTracker();

  const handlePress = (value: T) => {
    // Support data tracking
    if (track) {
      const trackData = track?.(value);
      doTracking(trackData.event, trackData.data);
    }

    if (typeof onPress === "function") onPress(value);
  };

  return <TabsSeo {...rest} onPress={handlePress} />;
}
