import { useContentResource, useImageResource } from "@ctv/core";
import { Image, TextSeo, ViewSeo } from "@ctv/momentum";
import BenefitItem from "./BenefitItem";
import cssStyles from "./CompanyBenefit.module.css";

const logos = [
  "/images/benefit/security-logo.svg",
  "/images/benefit/visa-verified-logo.svg",
  "/images/benefit/mastercard-logo.svg",
];
export default function CompanyBenefit() {
  const cr = useContentResource().CorporateRevampHomePage;
  const images = useImageResource().CorporateRevampHomePage;
  return (
    <ViewSeo className={cssStyles.container} align="center">
      <TextSeo variant="display">{cr.benefitTitle}</TextSeo>
      <ViewSeo
        className={cssStyles.benefitGroup}
        justify="evenly"
        row
        align="start"
      >
        <BenefitItem
          imageAlt={images.companyIntegrationBenefit.altText}
          imageUrl={images.companyIntegrationBenefit.link}
          title={cr.integrationBenefitTitle}
          subtitle={cr.integrationBenefitSubtitle}
        />
        <BenefitItem
          imageAlt={images.flexiblePaymentBenefit.altText}
          imageUrl={images.flexiblePaymentBenefit.link}
          title={cr.flexibleBenefitTitle}
          subtitle={cr.flexibleBenefitSubtitle}
        >
          <ViewSeo spacing="m" row>
            {logos.map((link) => (
              <Image key={link} alt="" src={link} height={28} />
            ))}
          </ViewSeo>
        </BenefitItem>
        <BenefitItem
          imageAlt={images.customerServiceBenefit.altText}
          imageUrl={images.customerServiceBenefit.link}
          title={cr.csBenefitTitle}
          subtitle={cr.csBenefitSubtitle}
        />
      </ViewSeo>
    </ViewSeo>
  );
}
