import Image from "next/image";

import { TextSeo, ViewSeo } from "@ctv/momentum";

import styles from "./ProductBadge.module.css";

type Props = {
  src: string;
  text: string;
};

export default function ProductBadge(props: Props) {
  const { src, text } = props;

  return (
    <ViewSeo
      className={styles.container}
      row
      spacing="xxs"
      justify="center"
      align="center"
    >
      <Image src={src} alt={text} width={16} height={16} />
      <TextSeo variant="ui-small" ink="white-primary">
        {text}
      </TextSeo>
    </ViewSeo>
  );
}
