import {
  CardSeo,
  DividerSeo,
  Image,
  TextSeo,
  Token,
  ViewSeo,
  useHover,
} from "@ctv/momentum";
import CheckmarkIcon from "@traveloka/icon-kit-web/react/IcSystemCheckmark";
import { ReactNode } from "react";
import cssStyles from "./OfferCard.module.css";

type Props = {
  headerClassName?: string;
  actionButton: ReactNode;
  headerImageUrl: string;
  title: string;
  items: string[];
};

export default function OfferCard(props: Props) {
  const { actionButton, headerClassName, headerImageUrl, title, items } = props;
  const [hover, handlers] = useHover();
  return (
    <CardSeo
      {...handlers}
      className={cssStyles.card}
      elevation={hover ? "hover" : "raised"}
    >
      <ViewSeo
        spacing="m"
        className={[cssStyles.header, headerClassName].join(" ")}
        row
      >
        <Image
          alt="Card background"
          src={headerImageUrl}
          className={cssStyles.cardBackground}
        />
        <TextSeo
          className={cssStyles.cardTitle}
          ink="white-primary"
          variant="title-1"
        >
          {title}
        </TextSeo>
      </ViewSeo>
      <DividerSeo subtle />
      <ViewSeo spacing="s" className={cssStyles.itemGroup}>
        {items.map((item, index) => (
          <ViewSeo key={index} spacing="xs" row>
            <CheckmarkIcon color={Token.color.greenPrimary} />
            <TextSeo variant="ui-baseline" ink="black-primary">
              {item}
            </TextSeo>
          </ViewSeo>
        ))}
      </ViewSeo>
      <DividerSeo subtle />
      {actionButton}
    </CardSeo>
  );
}
