import Link from "next/link";

import { useContentResource, useImageResource, useTracker } from "@ctv/core";
import { ButtonSeo, Image, TextSeo, ViewSeo } from "@ctv/momentum";
import { trackMerchantLendingButtonClick as trackData } from "@ctv/shared/tracking/landing-page/buttonClick";

import { CSF_URL } from "../constants";

import cssStyles from "./CSFBanner.module.css";

export default function CSFBanner() {
  const cr = useContentResource().CaturnusaSejahteraFinanceBanner;
  const images = useImageResource().CaturnusaSejahteraFinanceBanner;

  const track = useTracker();

  function trackCSFButtonClick() {
    track(trackData.event, trackData.data);
  }

  return (
    <ViewSeo align="center" className={cssStyles.container} row>
      <Image
        alt={images.background.altText}
        src={images.background.link}
        width={440}
        height={336}
        className={cssStyles.background}
      />
      <ViewSeo className={cssStyles.contentGroup} spacing="m">
        <Image
          src={images.logo.link}
          alt={images.logo.altText}
          height={48}
          width={109}
        />
        <TextSeo variant="display" ink="white-primary">
          {cr.title}
        </TextSeo>
        <TextSeo
          className={cssStyles.content}
          variant="ui-large"
          ink="white-primary"
        >
          {cr.content}
        </TextSeo>
        <Link
          className={cssStyles.link}
          href={CSF_URL}
          target="_blank"
          onClick={trackCSFButtonClick}
        >
          <ButtonSeo
            variant="outline-white"
            className={cssStyles.button}
            text={cr.findOutButtonText}
          />
        </Link>
      </ViewSeo>
    </ViewSeo>
  );
}
