import htmr from "htmr";

import { LocalizedLink, useContentResource } from "@ctv/core";
import { ButtonSeo, Text, TextSeo, ViewSeo } from "@ctv/momentum";
import {
  trackFeatureComparisonButtonClick,
  trackServiceOfferSignUpButtonClick,
} from "@ctv/shared/tracking/landing-page/buttonClick";

import OfferCard from "./OfferCard";

import cssStyles from "./CompanyOffer.module.css";

export default function CompanyOffer() {
  const cr = useContentResource().CorporateRevampHomeOfferSection;
  const itemsViaTraveloka = [
    cr.offerItemViaTraveloka1,
    cr.offerItemViaTraveloka2,
    cr.offerItemViaTraveloka3,
    cr.offerItemViaTraveloka4,
  ];
  const itemsViaWebPlatform = [
    cr.offerItemViaWebPlatform1,
    cr.offerItemViaWebPlatform2,
    cr.offerItemViaWebPlatform3,
    cr.offerItemViaWebPlatform4,
  ];
  return (
    <ViewSeo align="center" spacing="xl">
      <TextSeo variant="display">{cr.offerSectionTitle}</TextSeo>

      <ViewSeo spacing="xl" row>
        <OfferCard
          headerImageUrl="/images/desktop-bizcredit-card-header.png"
          headerClassName={cssStyles.leftCard}
          items={itemsViaTraveloka}
          actionButton={
            <TextSeo
              className={cssStyles.cardActionText}
              variant="ui-baseline"
              ink="black-secondary"
            >
              {cr.comingSoonText}
            </TextSeo>
          }
          title={cr.bookViaTravelokaTitle}
        />
        <OfferCard
          headerClassName={cssStyles.rightCard}
          headerImageUrl="/images/desktop-ctv-card-header.png"
          items={itemsViaWebPlatform}
          actionButton={
            <LocalizedLink
              href={"/register"}
              track={trackServiceOfferSignUpButtonClick}
            >
              <ButtonSeo
                variant="main-cta"
                className={[cssStyles.button, cssStyles.signUpButton].join(" ")}
                text={cr.signUpButtonText}
              />
            </LocalizedLink>
          }
          title={cr.bookViaWebPlatformTitle}
        />
      </ViewSeo>
      <Text>
        {htmr(cr.featureComparisonText, {
          transform: {
            a: (props) => (
              <LocalizedLink
                href={"/features#feature-table"}
                className={cssStyles.link}
                track={trackFeatureComparisonButtonClick}
              >
                {props.children}
              </LocalizedLink>
            ),
          },
        })}
      </Text>
    </ViewSeo>
  );
}
