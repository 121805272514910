"use client";
import Link from "next/link";
import { useEffect } from "react";

import {
  useAuth,
  useContentResource,
  useLocalizedRouterServer,
  useTracker,
} from "@ctv/core";
import { ButtonSeo, Token } from "@ctv/momentum";
import { PageSeo } from "@ctv/shared/components/Layout";
import {
  event,
  landingPageTracking as tracking,
} from "@ctv/shared/tracking/landing-page";
import { trackArrangeAMeetingButtonClick as meetingData } from "@ctv/shared/tracking/landing-page/buttonClick";
import { UrsulaTrackingSensor as Sensor } from "@ctv/shared/tracking/landing-page/sensor";

import CalendarIcon from "@traveloka/icon-kit-web/react/IcSystemCalendar16";

import { LandingPageProps } from "../../page";
import BusinessNumberSection from "./BusinessNumberSection/BusinessNumberSection";
import CSFBanner from "./CSFBanner/CSFBanner";
import CompanyBenefit from "./CompanyBenefit/CompanyBenefit";
import CompanyMarquee from "./CompanyMarquee/CompanyMarquee";
import CompanyOffer from "./CompanyOffer/CompanyOffer";
import SimplifyBenefit from "./CompanySimplifyBenefit/SimplifyBenefit";
import ConanArticles from "./ConanArticles/ConanArticles";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import Hero from "./Hero/Hero";
import HeroContent from "./Hero/HeroContent";
import { MEETING_URL } from "./constants";

import cssStyles from "./PreLogin.module.css";

export default function PreLogin(props: LandingPageProps) {
  const auth = useAuth();
  const router = useLocalizedRouterServer();

  useEffect(() => {
    if (auth.isAuthenticated) {
      router.replace("/home");
    }
  }, [auth.isAuthenticated, router]);

  if (auth.isAuthenticated) {
    return null;
  }

  return <NewPreLogin {...props} />;
}

function NewPreLogin(props: LandingPageProps) {
  const { recentArticles } = props;

  const track = useTracker();

  const cr = useContentResource().CorporateRevampHomePage;

  useEffect(() => {
    track(event.CORPORATE_TRAVEL, tracking.LOAD_PAGE);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only run once
  }, []);

  return (
    <PageSeo>
      <Header track="landing-page" />
      <Hero>
        <HeroContent />
      </Hero>
      <CompanyMarquee />
      <Sensor data={tracking.USP_SECTION} event={event.CORPORATE_TRAVEL}>
        <CompanyBenefit />
      </Sensor>
      <Sensor data={tracking.FEATURE_SECTION} event={event.CORPORATE_TRAVEL}>
        <SimplifyBenefit />
      </Sensor>
      <Sensor data={tracking.OFFER_SECTION} event={event.CORPORATE_TRAVEL}>
        <CompanyOffer />
      </Sensor>

      {/* Sensor component inside the ConanArticles component */}
      <ConanArticles recentArticles={recentArticles} />

      <Sensor data={tracking.CSF_SECTION} event={event.CORPORATE_TRAVEL}>
        <CSFBanner />
      </Sensor>
      <Sensor data={tracking.VIDEO_SECTION} event={event.CORPORATE_TRAVEL}>
        <BusinessNumberSection />
      </Sensor>
      <Sensor data={tracking.FOOTER_SECTION} event={event.CORPORATE_TRAVEL}>
        <Footer />
      </Sensor>
      <Link
        href={MEETING_URL}
        onClick={() => track(meetingData.event, meetingData.data)}
      >
        <ButtonSeo
          startIcon={<CalendarIcon color={Token.color.lightPrimary} />}
          className={cssStyles.button}
          text={cr.arrangeMeetingButtonText}
        />
      </Link>
    </PageSeo>
  );
}
