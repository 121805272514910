import { Image, Text, Token, ViewSeo } from "@ctv/momentum";

import IcSystemStatusOkDoneFill16 from "@traveloka/icon-kit-web/react/IcSystemStatusOkDoneFill16";

import styles from "./BenefitTabItem.module.css";

type Props = {
  imageSrc: string;
  imageAlt: string;
  title: string;
  points: string[];
  visible: boolean;
};

export default function BenefitTabItem(props: Props) {
  const { imageSrc, imageAlt, title, points, visible } = props;

  return (
    <ViewSeo
      className={`${styles.container} ${visible ? styles.show : styles.hide}`}
      spacing="l"
    >
      <Image
        className={styles.image}
        src={imageSrc}
        alt={imageAlt}
        width={256}
        height={200}
      />
      <ViewSeo spacing="m">
        <Text variant="title-2" ink="interactive">
          {title}
        </Text>
        <ViewSeo spacing="s">
          {points.map((point, index) => (
            <ViewSeo key={index} row spacing="xs">
              <IcSystemStatusOkDoneFill16
                color={Token.color.greenPrimary}
                width={16}
                height={16}
              />
              <Text variant="ui-small">{point}</Text>
            </ViewSeo>
          ))}
        </ViewSeo>
      </ViewSeo>
    </ViewSeo>
  );
}
