import { TextSeo, ViewSeo } from "@ctv/momentum";
import { ReactElement } from "react";
import styles from "./iconBadge.module.css";

type Props = {
  icon?: ReactElement;
  text: string;
};
export default function IconBadge(props: Props) {
  const { icon, text } = props;
  return (
    <ViewSeo spacing="xxs" className={styles.badge} align="center" row>
      {icon}
      <TextSeo variant="ui-small" ink="white-primary">
        {text}
      </TextSeo>
    </ViewSeo>
  );
}
