import { useContentResource } from "@ctv/core";
import { TextSeo, ViewSeo } from "@ctv/momentum";

import BenefitTabContent from "./BenefitTabContent";

import styles from "./BenefitTab.module.css";

export default function BenefitTab() {
  const cr = useContentResource().CorporateRevampHomePage;

  return (
    <ViewSeo className={styles.container} spacing="l">
      <TextSeo className={styles.title} variant="headline">
        {cr.simplifyBenefitTitle}
      </TextSeo>
      <BenefitTabContent />
    </ViewSeo>
  );
}
