import React from "react";

import { Modal } from "@ctv/momentum";

type Props = {
  isVisible: boolean;
  youtubeEmbedSrc: string;
  width?: number;
  onCloseModal: () => void;
};

function VideoModal(props: Props) {
  const { isVisible, youtubeEmbedSrc, width, onCloseModal } = props;
  const iframelWidth = width ? width : 860;
  const iframeHeight = (iframelWidth * 9) / 16;

  return (
    <Modal
      visible={isVisible}
      onClose={onCloseModal}
      render={() => (
        <iframe
          frameBorder={0}
          src={youtubeEmbedSrc}
          width={iframelWidth}
          height={iframeHeight}
        />
      )}
    />
  );
}

export default VideoModal;
