import htmr from "htmr";
import Link from "next/link";

import { useContentResource, useLocalizedDateFormat } from "@ctv/core";
import { ArticleData } from "@ctv/core/cnt/conan/api";
import { CardSeo, Image, TextSeo, ViewSeo } from "@ctv/momentum";
import TrackingButtonSeo from "@ctv/shared/components/Tracking/TrackingButtonSeo";
import { trackReadArticleButtonClick } from "@ctv/shared/tracking/landing-page/buttonClick";

import styles from "./ConanArticleItem.module.css";

type Props = {
  article: ArticleData;
  trackReadMoreButtonClick?: ReturnType<typeof trackReadArticleButtonClick>;
};

export default function ConanArticleItem(props: Props) {
  const { article, trackReadMoreButtonClick } = props;

  const cr = useContentResource().CorporateRevampHomePage;
  const { format } = useLocalizedDateFormat();

  return (
    <CardSeo
      className={styles.articleCard}
      elevation="raised"
      spacing="s"
      overflow="hidden"
    >
      <ViewSeo className={styles.imageContainer}>
        <Image
          src={article.image}
          alt={article.tags.join("")}
          width={295}
          height={125}
        />
      </ViewSeo>
      <ViewSeo className={styles.articleContent} spacing="xs">
        <TextSeo className={styles.articleTitle}>{article.title}</TextSeo>
        <TextSeo
          className={styles.articleSummary}
          variant="ui-tiny"
          ink="black-secondary"
        >
          {htmr(article.summary)}
        </TextSeo>
        <ViewSeo row justify="between">
          <ViewSeo justify="between">
            <TextSeo variant="ui-tiny" ink="black-secondary">
              {article.tags.map((tag) => "#" + tag).join(" ")}
            </TextSeo>
            <TextSeo variant="ui-tiny" ink="black-secondary">
              {format(article.publishedDate, "SHORT_MONTH")}
            </TextSeo>
          </ViewSeo>
          <Link className={styles.link} href={article.url}>
            <TrackingButtonSeo
              className={styles.readMoreButton}
              text={cr.articlesReadMoreButtonText}
              size="small"
              variant="outline-primary"
              track={trackReadMoreButtonClick}
            />
          </Link>
        </ViewSeo>
      </ViewSeo>
    </CardSeo>
  );
}
