import { useContentResource, useImageSlider } from "@ctv/core";
import { Image, TextSeo, ViewSeo, useInitialRenderState } from "@ctv/momentum";
import Marquee from "react-fast-marquee";
import cssStyles from "./CompanyMarquee.module.css";

export default function CompanyMarquee() {
  const cr = useContentResource().CorporateRevampHomePage;
  const slider = useImageSlider().CorporateRevampHomePage;
  const rendered = useInitialRenderState();
  return (
    <ViewSeo className={cssStyles.container} align="center" spacing="xxl" row>
      <TextSeo
        className={cssStyles.description}
        variant="ui-baseline"
        ink="black-primary"
      >
        {cr.marqueeText}
      </TextSeo>
      {rendered ? (
        <Marquee autoFill gradient className={cssStyles.marquee}>
          {slider
            .filter((data) => data.groupName === "partnerLogo")
            .map((data) => {
              return (
                <Image
                  className={cssStyles.image}
                  key={data.link}
                  alt={data.altText}
                  src={data.link}
                  height={48}
                />
              );
            })}
        </Marquee>
      ) : (
        <ViewSeo className={cssStyles.initialImages}>
          {slider
            .filter((data) => data.groupName === "partnerLogo")
            .map((data) => {
              return (
                <Image
                  className={cssStyles.image}
                  key={data.link}
                  alt={data.altText}
                  src={data.link}
                  height={48}
                />
              );
            })}
        </ViewSeo>
      )}
    </ViewSeo>
  );
}
