import Text, { TextProps, TextSeo } from "../../Text";
import { InputPropsSeo } from "./InputSeo";

export type InputLabelProps = {
  disabled?: boolean;
  inputId?: string;
  required?: boolean;
  size?: InputPropsSeo["size"];
  text: string;
  className?: string;
};

const variantMap: Record<string, TextProps["variant"]> = {
  small: "ui-tiny-bold",
  medium: "ui-small-bold",
};

export default function InputLabelSeo(props: InputLabelProps) {
  const {
    disabled,
    inputId,
    required,
    size = "medium",
    className,
    text,
  } = props;

  return (
    <Text
      // @ts-expect-error label does not exist in typing
      accessibilityRole="label"
      ellipsizeMode="tail"
      ink={disabled ? "black-muted" : "black-primary"}
      numberOfLines={2}
      variant={variantMap[size]}
      htmlFor={inputId}
      className={className}
    >
      {text}
      {required && (
        <TextSeo aria-hidden ink="alert" variant={variantMap[size]}>
          &thinsp;*
        </TextSeo>
      )}
    </Text>
  );
}
