import { useContentResource } from "@ctv/core";
import { Image, TextSeo, ViewSeo } from "@ctv/momentum";
import IconBadge from "../IconBadge/IconBadge";
import cssStyle from "./HeroContent.module.css";
import RegistrationForm from "./RegistrationForm";

export default function HeroContent() {
  const cr = useContentResource().CorporateRevampHomePage;

  return (
    <ViewSeo className={cssStyle.container} spacing="m">
      <ViewSeo spacing="xs">
        <ViewSeo spacing="xs" row>
          <IconBadge
            text={cr.flightsText}
            icon={
              <Image
                width={16}
                height={16}
                alt="Flight Icon"
                src={"/images/white-flight-icon.svg"}
              />
            }
          />
          <IconBadge
            text={cr.hotelsText}
            icon={
              <Image
                width={16}
                height={16}
                alt="Hotel icon"
                src={"/images/white-hotel-icon.svg"}
              />
            }
          />
        </ViewSeo>
        <TextSeo ink="white-primary" variant="hero">
          {cr.title}
        </TextSeo>
        <TextSeo variant="ui-large" ink="white-primary">
          {cr.heroSubtitle}
        </TextSeo>
      </ViewSeo>

      <RegistrationForm />
    </ViewSeo>
  );
}
