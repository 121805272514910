import { useMemo, useRef, useState } from "react";

import { useContentResource, useImageResource } from "@ctv/core";
import { TabSeo, TextSeo, ViewSeo } from "@ctv/momentum";
import DividerSeo from "@ctv/momentum/Divider/DividerSeo";
import TrackingTabsSeo from "@ctv/shared/components/Tracking/TrackingTabsSeo";
import { trackBenefitTabClick } from "@ctv/shared/tracking/landing-page/buttonClick";

import { SimplifyBenefitTabKey } from "../types";
import BenefitTabContent from "./BenefitTabContent";

import cssStyles from "./SimplifyBenefit.module.css";

type TabItem = {
  key: SimplifyBenefitTabKey;
  text: string;
};

const scrollOptions: ScrollIntoViewOptions = {
  block: "nearest",
  inline: "start",
};

export default function SimplifyBenefit() {
  const cr = useContentResource().CorporateRevampHomePage;
  const images = useImageResource().CorporateRevampHomePage;
  const [tab, setTab] = useState<SimplifyBenefitTabKey>("finance");
  const tabGroupRef = useRef<HTMLDivElement>(null);
  const tabFinanceRef = useRef<HTMLDivElement>(null);
  const tabHrRef = useRef<HTMLDivElement>(null);
  const tabEmployeeRef = useRef<HTMLDivElement>(null);
  const tabItems: TabItem[] = useMemo(
    () => [
      { key: "finance", text: cr.financeBenefitTab },
      { key: "hr", text: cr.HRBenefitTab },
      { key: "employee", text: cr.employeeBenefitTab },
    ],
    [cr]
  );
  const financeBenefits = useMemo(
    () => [
      cr.financeBenefitItem1,
      cr.financeBenefitItem2,
      cr.financeBenefitItem3,
    ],
    [cr]
  );
  const hrBenefits = useMemo(
    () => [cr.HRBenefitItem1, cr.HRBenefitItem2, cr.HRBenefitItem3],
    [cr]
  );
  const employeeBenefits = useMemo(
    () => [
      cr.employeeBenefitItem1,
      cr.employeeBenefitItem2,
      cr.employeeBenefitItem3,
    ],
    [cr]
  );
  function handleTabPress(value: SimplifyBenefitTabKey) {
    const financeRefWidth =
      tabFinanceRef.current?.getBoundingClientRect().width ?? 0;
    const employeeRefWidth =
      tabHrRef.current?.getBoundingClientRect().width ?? 0;

    switch (value) {
      case "finance":
        tabGroupRef.current?.scrollTo({ left: 0, behavior: "smooth" });
        break;
      case "hr":
        tabGroupRef.current?.scrollTo({
          left: financeRefWidth,
          behavior: "smooth",
        });
        break;
      case "employee":
        tabGroupRef.current?.scrollTo({
          left: financeRefWidth + employeeRefWidth,
          behavior: "smooth",
        });
        break;
    }
    setTab(value);
  }
  return (
    <ViewSeo align="center" spacing="xxl" className={cssStyles.container}>
      <TextSeo variant="display" ink="black-primary">
        {cr.simplifyBenefitTitle}
      </TextSeo>
      <ViewSeo align="center">
        <TrackingTabsSeo<SimplifyBenefitTabKey>
          value={tab}
          onPress={handleTabPress}
          track={trackBenefitTabClick}
        >
          {tabItems.map((item) => (
            <TabSeo
              style={{ lineHeight: 32 }}
              key={item.key}
              value={item.key}
              label={item.text}
            />
          ))}
        </TrackingTabsSeo>
        <DividerSeo className={cssStyles.divider} />
        <ViewSeo
          ref={tabGroupRef}
          align="start"
          className={cssStyles.contentGroup}
          spacing="m"
          row
        >
          <BenefitTabContent
            ref={tabFinanceRef}
            visible={tab === "finance"}
            imageUrl={images.financeBenefit.link}
            altText={images.financeBenefit.altText}
            title={cr.financeBenefitTitle}
            benefits={financeBenefits}
          />
          <BenefitTabContent
            ref={tabHrRef}
            visible={tab === "hr"}
            imageUrl={images.HRBenefit.link}
            altText={images.HRBenefit.altText}
            title={cr.HRBenefitTitle}
            benefits={hrBenefits}
          />
          <BenefitTabContent
            ref={tabEmployeeRef}
            visible={tab === "employee"}
            imageUrl={images.employeeBenefit.link}
            altText={images.employeeBenefit.altText}
            title={cr.employeeBenefitTitle}
            benefits={employeeBenefits}
          />
        </ViewSeo>
      </ViewSeo>
    </ViewSeo>
  );
}
