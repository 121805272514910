import { useContentResource, useImageResource } from "@ctv/core";
import { Image, TextSeo, ViewSeo } from "@ctv/momentum";
import { useState } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import cssStyles from "./BusinessNumberSection.module.css";
import VideoModal from "./VideoModal";

export default function BusinessNumberSection() {
  const cr = useContentResource().CorporateRevampBusinessNumberSection;
  const images = useImageResource().CorporateRevampHomePage;
  const contents = [
    [cr.companyNumberTitle, cr.companyNumberSubtitle],
    [cr.industryNumberTitle, cr.industryNumberSubtitle],
    [cr.tripNumberTitle, cr.tripNumberSubtitle],
  ];
  const [visible, setVisible] = useState(false);
  return (
    <ViewSeo className={cssStyles.container}>
      <Image
        alt="Blue Background"
        src="/images/bg-business-number.png"
        className={cssStyles.background}
        height={632}
      />
      <Image
        alt="Background Illustration"
        src="/images/illustration-business-number.png"
        className={cssStyles.illustration}
        height={430}
      />
      <ViewSeo className={cssStyles.contentGroup} spacing="xxl">
        <TextSeo
          variant="display"
          ink="white-primary"
          className={cssStyles.title}
        >
          {cr.title}
        </TextSeo>
        <ViewSeo spacing="xxl" row>
          <ViewSeo className={cssStyles.thumbnail}>
            <TouchableOpacity onPress={() => setVisible(true)}>
              <Image
                alt={images.videoThumbnail.altText}
                src={images.videoThumbnail.link}
                width={391}
                height={213}
              />
            </TouchableOpacity>
          </ViewSeo>

          <ViewSeo spacing="m" className={cssStyles.contentNumberGroup}>
            {contents.map(([title, subtitle]) => (
              <ViewSeo key={title} spacing="xs">
                <TextSeo variant="display" ink="white-primary">
                  {title}
                </TextSeo>
                <TextSeo variant="ui-baseline" ink="white-primary">
                  {subtitle}
                </TextSeo>
              </ViewSeo>
            ))}
          </ViewSeo>
        </ViewSeo>
      </ViewSeo>
      <VideoModal
        isVisible={visible}
        onCloseModal={() => setVisible(false)}
        youtubeEmbedSrc={cr.youtubeEmbedSrc}
      />
    </ViewSeo>
  );
}
