import Link from "next/link";

import { CSF_URL } from "@ctv/app/[locale]/_components/pre-login/constants";
import { useContentResource, useImageResource, useTracker } from "@ctv/core";
import { ButtonSeo, Image, TextSeo, ViewSeo } from "@ctv/momentum";
import { trackMerchantLendingButtonClick as trackData } from "@ctv/shared/tracking/landing-page/buttonClick";

import styles from "./CsfBanner.module.css";

export default function CsfBanner() {
  const { content, findOutButtonText, title } =
    useContentResource().CaturnusaSejahteraFinanceBanner;
  const { backgroundMobile, logo } =
    useImageResource().CaturnusaSejahteraFinanceBanner;

  const track = useTracker();

  function trackCSFButtonClick() {
    track(trackData.event, trackData.data);
  }

  return (
    <ViewSeo className={styles.container}>
      <Image
        className={styles.backgroundImage}
        src={backgroundMobile.link}
        alt={backgroundMobile.altText}
      />
      <ViewSeo className={styles.content} spacing="m" align="center">
        <Image className={styles.logo} src={logo.link} alt={logo.altText} />
        <TextSeo
          className={styles.textCenter}
          variant="title-1"
          ink="white-primary"
        >
          {title}
        </TextSeo>
        <TextSeo
          className={styles.textCenter}
          variant="ui-small"
          ink="white-primary"
        >
          {content}
        </TextSeo>
        <Link href={CSF_URL} target="_blank" onClick={trackCSFButtonClick}>
          <ButtonSeo text={findOutButtonText} variant="outline-white" />
        </Link>
      </ViewSeo>
    </ViewSeo>
  );
}
