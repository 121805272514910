import { StyleSheet } from "react-native";

import { isReactText } from "@ctv/momentum/utils";
import Text from "../../Text";
import { ViewSeo } from "../../View";

export type InputHelperProps = {
  disabled?: boolean;
  endText?: string;
  nativeID?: string;
  startText?: string;
};

export default function InputHelperSeo(props: InputHelperProps) {
  const { disabled, endText, nativeID, startText } = props;
  const ink = disabled ? "black-muted" : "black-secondary";

  return (
    <ViewSeo id={nativeID} align="baseline" spacing="xs">
      {isReactText(startText) && (
        <Text
          ellipsizeMode="tail"
          ink={ink}
          numberOfLines={3}
          style={styles.startText}
          variant="ui-tiny"
        >
          {startText}
        </Text>
      )}
      {isReactText(endText) && (
        <Text
          ellipsizeMode="tail"
          ink={ink}
          numberOfLines={3}
          style={styles.endText}
          variant="ui-tiny"
        >
          {endText}
        </Text>
      )}
    </ViewSeo>
  );
}

const styles = StyleSheet.create({
  startText: {
    flex: 1,
  },
  endText: {
    marginStart: "auto",
    textAlign: "right",
  },
});
