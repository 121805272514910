import { useMemo, useState } from "react";

import { SimplifyBenefitTabKey } from "@ctv/app/[locale]/_components/pre-login/types";
import { useContentResource, useImageResource } from "@ctv/core";
import { TabSeo, ViewSeo } from "@ctv/momentum";
import TrackingTabsSeo from "@ctv/shared/components/Tracking/TrackingTabsSeo";
import { trackBenefitTabClick } from "@ctv/shared/tracking/landing-page/buttonClick";

import BenefitTabItem from "./BenefitTabItem";

import styles from "./BenefitTabContent.module.css";

export default function BenefitTabContent() {
  const [tab, setTab] = useState<SimplifyBenefitTabKey>("finance");

  const cr = useContentResource().CorporateRevampHomePage;
  const images = useImageResource().CorporateRevampHomePage;

  const tabItems = useMemo(
    () => [
      {
        key: "finance",
        text: cr.financeBenefitTab,
      },
      {
        key: "hr",
        text: cr.HRBenefitTab,
      },
      {
        key: "employee",
        text: cr.employeeBenefitTab,
      },
    ],
    [cr]
  );

  const points = useMemo(
    () => ({
      finance: [
        cr.financeBenefitItem1,
        cr.financeBenefitItem2,
        cr.financeBenefitItem3,
      ],
      hr: [cr.HRBenefitItem1, cr.HRBenefitItem2, cr.HRBenefitItem3],
      employee: [
        cr.employeeBenefitItem1,
        cr.employeeBenefitItem2,
        cr.employeeBenefitItem3,
      ],
    }),
    [cr]
  );

  return (
    <ViewSeo spacing="l">
      <TrackingTabsSeo<SimplifyBenefitTabKey>
        className={styles.tabsContainer}
        containerClassName={styles.tabsContainerOverflow}
        value={tab}
        onPress={setTab}
        variant="fixed"
        track={trackBenefitTabClick}
      >
        {tabItems.map((item) => (
          <TabSeo
            key={item.key}
            value={item.key}
            label={item.text}
            variant="title-3"
          />
        ))}
      </TrackingTabsSeo>
      <ViewSeo className={styles.wrapper}>
        <BenefitTabItem
          imageSrc={images.financeBenefit.link}
          imageAlt={images.financeBenefit.altText}
          points={points.finance}
          title={cr.financeBenefitTitle}
          visible={tab === "finance"}
        />
        <BenefitTabItem
          imageSrc={images.HRBenefit.link}
          imageAlt={images.HRBenefit.altText}
          points={points.hr}
          title={cr.HRBenefitTitle}
          visible={tab === "hr"}
        />
        <BenefitTabItem
          imageSrc={images.employeeBenefit.link}
          imageAlt={images.employeeBenefit.altText}
          points={points.employee}
          title={cr.employeeBenefitTitle}
          visible={tab === "employee"}
        />
      </ViewSeo>
    </ViewSeo>
  );
}
