import htmr from "htmr";

import { LocalizedLink, useContentResource } from "@ctv/core";
import { ButtonSeo, TextSeo, ViewSeo } from "@ctv/momentum";
import {
  trackFeatureComparisonButtonClick,
  trackServiceOfferSignUpButtonClick,
} from "@ctv/shared/tracking/landing-page/buttonClick";

import CompanyOfferCard from "./CompanyOfferCard";

import styles from "./CompanyOffer.module.css";

export default function CompanyOffer() {
  const cr = useContentResource().CorporateRevampHomeOfferSection;
  const itemsViaTraveloka = [
    cr.offerItemViaTraveloka1,
    cr.offerItemViaTraveloka2,
    cr.offerItemViaTraveloka3,
    cr.offerItemViaTraveloka4,
  ];
  const itemsViaWebPlatform = [
    cr.offerItemViaWebPlatform1,
    cr.offerItemViaWebPlatform2,
    cr.offerItemViaWebPlatform3,
    cr.offerItemViaWebPlatform4,
  ];

  return (
    <ViewSeo className={styles.container} spacing="xl">
      <TextSeo className={styles.title} variant="headline">
        {cr.offerSectionTitle}
      </TextSeo>
      <CompanyOfferCard
        action={<TextSeo ink="black-secondary">{cr.comingSoonText}</TextSeo>}
        bgImageAlt="biz credit"
        bgImageSrc="/images/mweb-biz-credit-card-header.png"
        items={itemsViaTraveloka}
        title={cr.bookViaTravelokaTitle}
      />
      <CompanyOfferCard
        action={
          <LocalizedLink
            href="/register"
            track={trackServiceOfferSignUpButtonClick}
          >
            <ButtonSeo
              className={styles.signUpButton}
              variant="main-cta"
              text={cr.signUpButtonText}
            />
          </LocalizedLink>
        }
        bgImageAlt="corporate travel"
        bgImageSrc="/images/mweb-ctv-card-header.png"
        items={itemsViaWebPlatform}
        title={cr.bookViaWebPlatformTitle}
      />
      <TextSeo className={styles.seeComparison}>
        {htmr(cr.featureComparisonText, {
          transform: {
            a: (props) => (
              <LocalizedLink
                href={"/features#feature-table"}
                className={styles.link}
                track={trackFeatureComparisonButtonClick}
              >
                {props.children}
              </LocalizedLink>
            ),
          },
        })}
      </TextSeo>
    </ViewSeo>
  );
}
