import { useContentResource, useImageResource } from "@ctv/core";
import { Image, TextSeo, ViewSeo } from "@ctv/momentum";

import BenefitItem from "./BenefitItem";

import styles from "./Benefit.module.css";

const logos = [
  "/images/benefit/security-logo.svg",
  "/images/benefit/visa-verified-logo.svg",
  "/images/benefit/mastercard-logo.svg",
];
export default function Benefit() {
  const cr = useContentResource().CorporateRevampHomePage;
  const {
    companyIntegrationBenefit,
    flexiblePaymentBenefit,
    customerServiceBenefit,
  } = useImageResource().CorporateRevampHomePage;

  return (
    <ViewSeo className={styles.container} spacing="xl" align="center">
      <TextSeo className={styles.title} variant="headline">
        {cr.benefitTitle}
      </TextSeo>
      <ViewSeo spacing="xxxxxl">
        <BenefitItem
          imageData={companyIntegrationBenefit}
          title={cr.integrationBenefitTitle}
          subtitle={cr.integrationBenefitSubtitle}
        />
        <BenefitItem
          imageData={flexiblePaymentBenefit}
          title={cr.flexibleBenefitTitle}
          subtitle={cr.flexibleBenefitSubtitle}
        >
          <ViewSeo spacing="m" row>
            {logos.map((link) => (
              <Image key={link} alt="" src={link} height={28} />
            ))}
          </ViewSeo>
        </BenefitItem>
        <BenefitItem
          imageData={customerServiceBenefit}
          title={cr.csBenefitTitle}
          subtitle={cr.csBenefitSubtitle}
        />
      </ViewSeo>
    </ViewSeo>
  );
}
