import { Image, TextSeo, Token, ViewSeo } from "@ctv/momentum";
import DoneIcon from "@traveloka/icon-kit-web/react/IcSystemStatusOkDoneFill12";
import { forwardRef } from "react";
import cssStyles from "./BenefitTabContent.module.css";

type Props = {
  altText?: string;
  imageUrl: string;
  title: string;
  benefits: string[];
  visible?: boolean;
};

export default forwardRef<HTMLDivElement, Props>(function BenefitTabContent(
  props: Props,
  ref
) {
  const { altText = "", imageUrl, title, benefits, visible = true } = props;

  return (
    <ViewSeo ref={ref} className={cssStyles.container} row spacing="xl">
      <Image width={320} height={250} alt={altText} src={imageUrl} />
      <ViewSeo className={cssStyles.contentGroup} spacing="m">
        <TextSeo variant="title-1" ink="interactive">
          {title}
        </TextSeo>
        {benefits.map((benefit, index) => (
          <ViewSeo key={index} align="center" row spacing="xs">
            <DoneIcon color={Token.color.greenPrimary} />
            {benefit}
          </ViewSeo>
        ))}
      </ViewSeo>
    </ViewSeo>
  );
});
