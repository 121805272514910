import { ReactElement } from "react";

import { CardSeo, Image, TextSeo, Token, ViewSeo } from "@ctv/momentum";

import IcSystemCheckmark from "@traveloka/icon-kit-web/react/IcSystemCheckmark";

import styles from "./CompanyOfferCard.module.css";

type Props = {
  title: string;
  bgImageSrc: string;
  bgImageAlt: string;
  items: string[];
  action: ReactElement;
};
export default function CompanyOfferCard(props: Props) {
  const { title, bgImageSrc, bgImageAlt, items, action } = props;

  return (
    <CardSeo className={styles.container} elevation="raised">
      <ViewSeo className={styles.header}>
        <Image
          alt={bgImageAlt}
          src={bgImageSrc}
          className={styles.cardBackground}
        />
        <TextSeo className={styles.title} variant="title-1" ink="white-primary">
          {title}
        </TextSeo>
      </ViewSeo>
      <ViewSeo className={styles.offerList} spacing="s">
        {items.map((item, index) => (
          <ViewSeo key={index} row spacing="xs">
            <IcSystemCheckmark
              color={Token.color.greenPrimary}
              width={24}
              height={24}
            />
            <TextSeo variant="ui-small">{item}</TextSeo>
          </ViewSeo>
        ))}
      </ViewSeo>
      <ViewSeo className={styles.footer}>{action}</ViewSeo>
    </CardSeo>
  );
}
